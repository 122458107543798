import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { IconArrowRight, IconMail2, IconPhone, IconPlace } from "../components/icons/Icons"
import Map from "../components/common/Map"
import ThankYouHeroBG from "../components/ThankYouHeroBG"
import AdditionalForms from "../components/common/AdditionalForms"

const FormThankYou = () => {
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />

      <section id="Watch Instant Quote Form">
        <ThankYouHeroBG>
          <div className="z-20 px-10 text-5xl font-black text-link md:py-10 md:text-8xl xl:text-9xl">
            THANK YOU
          </div>
          <div
            role={"presentation"}
            className="absolute z-0 -translate-y-1 transform px-10 text-5xl font-black text-white blur-sm md:py-10 md:text-8xl xl:text-9xl"
          >
            THANK YOU
          </div>
          <div
            role={"presentation"}
            className="absolute z-0 translate-y-1 transform px-10 text-5xl font-black text-accent opacity-40 blur-sm md:py-10 md:text-8xl xl:text-9xl"
          >
            THANK YOU
          </div>
        </ThankYouHeroBG>

        <div className="h-full bg-link py-10 text-white">
          <div className="flex h-full w-full flex-col justify-center align-middle">
            <div className="flex w-full flex-col align-middle">
              <div className="flex justify-center align-middle " />
              <div className="flex justify-center align-middle">
                <p className="w-3/4 py-10 text-center md:py-0 xl:w-1/3">
                  Your information has been sent to our team of buying professional. Someone will
                  contact you shortly. Please be sure to check the email you have provided for
                  additional questions or price quote.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h2 className={"py-10 text-center text-4xl uppercase"}>WHAT TO EXPECT</h2>
      </section>
      <section className={"w-full p-10"}>
        <div className={"how-it-works flex flex-wrap items-center justify-center gap-4 px-4"}>
          <Link
            to="/what-are-you-looking-to-sell/"
            className={"hoverScale flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"}
          >
            <StaticImage src={"../images/homepage/value.png"} alt={"Valuation"} />
            <h3>Request a Quote</h3>
            <p>
              Find out how much is your watch or jewelry worth? The more information & photos you
              share with us the more accurate the market valuation will be.
            </p>
          </Link>
          <div className={"hidden flex-shrink self-center md:block"}>
            <IconArrowRight className={"text-accent"} />
          </div>
          <Link
            to="/ez-ship-box-request-form/"
            className={"hoverScale flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"}
          >
            <StaticImage src={"../images/homepage/shipping.png"} alt={"Shipping"} />
            <h3>Send Us Your Jewelry</h3>
            <p>
              If you like the quote you’ll receive a pre-paid shipping label including insurance.
              Face-to-face collection can also be arranged.
            </p>
          </Link>
          <div className={"hidden flex-shrink self-center lg:block"}>
            <IconArrowRight className={"text-accent"} />
          </div>
          <div className={"flex-grow-0 text-center text-link md:w-2/5 lg:w-auto lg:flex-1"}>
            <StaticImage src={"../images/homepage/inspection.png"} alt={"Inspection"} />
            <h3>Full Jewelry Inspection</h3>
            <p>
              Once we receive your jewelry our qualified experts will inspect its condition and
              authenticity.
            </p>
          </div>
          <div className={"hidden flex-shrink self-center md:block"}>
            <IconArrowRight className={"text-accent"} />
          </div>
          <div className={"flex-grow-0 text-center text-link md:w-2/5 lg:w-auto lg:flex-1"}>
            <StaticImage src={"../images/homepage/payment.png"} alt={"Payment"} />
            <h3>Receive Payment</h3>
            <p>Once finalized you will quickly receive payment via check or wire payment.</p>
          </div>
        </div>
      </section>

      <AdditionalForms />

      <div className={"container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0"}>
        <section className={"w-full"}>
          <h2 className={"text-center text-4xl uppercase"}>Still have Questions?</h2>
        </section>
        <section className={"w-full"}>
          <p className={"mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5"}>
            Our highly trained specialists, coupled with our international sales reach, allow us to
            offer the very best prices for your jewelry.
          </p>
        </section>

        <section className={"flex w-full flex-wrap gap-16 lg:flex-nowrap"}>
          <div
            className={
              "align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPlace />
            </div>
            <div className={"text-3xl uppercase"}>Visit Us</div>
            <div className={"mt-16 text-link"}>9595 Harding Avenue, Bal Harbour Florida 33154</div>
          </div>
          <div
            className={
              "align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPhone />
            </div>
            <div className={"text-3xl uppercase"}>Call Us</div>
            <div className={"mt-16 text-accent"}>
              <a href={"tel:+13057706955"}>tel: 305 770 6955</a>
            </div>
          </div>
          <div className={"align-center flex w-full flex-col justify-center text-center lg:w-1/3"}>
            <div className={"mb-8 text-5xl text-accent"}>
              <IconMail2 />
            </div>
            <div className={"text-3xl uppercase"}>Email Us</div>
            <div className={"mt-16 text-accent"}>
              <a href={"mailto:sell@grayandsons.com"}>sell@grayandsons.com</a>
            </div>
          </div>
        </section>
      </div>

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default FormThankYou
